import React, { useCallback } from "react";
import "./App.css";
import { BuisnessCard, Shaker, useKeyListener } from "./components";

function App() {
  const goToFun = useCallback(() => {
    window.location.pathname = "/cards";
  }, []);

  useKeyListener(67, goToFun);

  return (
    <Shaker onShake={goToFun}>
      <div className="App">
        <BuisnessCard />
      </div>
    </Shaker>
  );
}

export default App;
