import React from 'react';
import './index.css';
import ReactIMG from "./frameworks/react.svg";
import FlutterIMG from "./frameworks/flutter.svg";
import LinkedinIMG from "./frameworks/linkedin.svg";

function Component() {
    return (
        <div className="business-card">
            <div className="profile">
                <div className="name">
                    <h3>Paul Wasson</h3> <a href="/vcard">enregistrer</a>
                </div>
                <span>Ingénieur <code>développeur</code> indépendant </span>
                <span className="since">Depuis 2016</span>
            </div>
            <div className="badge-row">
                <a href="https://reactjs.org/">
                    <img className="badge" alt="logo-react" src={ReactIMG} />
                </a>
                <a href="https://flutter.dev/">
                    <img className="badge" alt="logo-flutter" src={FlutterIMG} />
                </a>
                <div className="full" />
                <a href="https://www.linkedin.com/in/paulwasson/">
                    <img className="badge clickable" alt="logo-linkedin" src={LinkedinIMG} />
                </a>
            </div>
        </div>
    );
}

export default Component;
