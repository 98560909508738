import { useCallback, useEffect } from "react";

export function useKeyListener(keycode, onPress) {
  const onEvt = useCallback(
    (event) => {
      if (onPress && (event.isComposing || event.keyCode === keycode)) {
        onPress();
      }
    },
    [onPress, keycode]
  );

  useEffect(() => {
    window.addEventListener("keydown", onEvt);
    return () => {
      window.removeEventListener("keydown", onEvt);
    };
  }, [onEvt]);
}
