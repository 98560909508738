import { useEffect, useMemo } from "react";
import Shake from "shake.js";
import "./index.css";

function Component({ onShake, children }) {
  const shakeEvent = useMemo(
    () =>
      new Shake({
        threshold: 12, // optional shake strength threshold
        timeout: 1000, // optional, determines the frequency of event generation
      }),
    []
  );
  useEffect(() => {
    shakeEvent.start();
    window.addEventListener("shake", onShake, false);
    return () => {
      window.removeEventListener("shake", onShake, false);
      shakeEvent.stop();
    };
  }, [shakeEvent, onShake]);

  return children;
}

export default Component;
